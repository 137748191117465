// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@font-face {
  font-family: "'Montserrat',  sans-serif";
  src: url("./fonts/montserrat.regular.ttf");
}

$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat',
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fundmarket-primary: mat.define-palette(mat.$indigo-palette);
$fundmarket-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fundmarket-theme: mat.define-light-theme(
  (
    color: (
      primary: $fundmarket-primary,
      accent: $fundmarket-accent,
    ),
  )
);

@include mat.all-component-themes($fundmarket-theme);
