:root {
  --primary-color: #5ab2e2;
  --secondary-color: #122144;
  --third-color: #85c6ea;
  --fundmarket-grey: #dee2e6;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat.regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: var(--fundmarket-grey) !important;
}

.bg-color-secondary {
  background-color: var(--secondary-color) !important;
}

/* MatDatePicker */
.mat-focused {
  color: var(--primary-color) !important;
}

.mat-form-field-underline {
  background-color: var(--primary-color) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary-color) !important;
}

.mat-form-field-ripple {
  background-color: var(--primary-color) !important;
}

.mat-datepicker-toggle {
  color: var(--primary-color) !important;
}

.mat-datepicker-toggle:active {
  color: var(--primary-color) !important;
}

.mat-calendar-body-selected {
  background-color: var(--primary-color) !important;
  color: black !important;
}

.mat-focus-indicator::before {
  background-color: var(--primary-color) !important;
}

.mat-calendar-body-cell-content:hover {
  background-color: var(--primary-color) !important;
  color: black !important;
}

.mat-calendar-body-cell-contet-preview::after {
  background-color: var(--primary-color) !important;
  color: red !important;
}

.mat-calendar-body-cell-highligt:hover {
  background-color: var(--primary-color) !important;
  color: black !important;
}

.mat-calendar-body-cell-content::before {
  background-color: var(--primary-color) !important;
  color: black !important;
}

/*Mat Spinner*/
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary-color);
}

.card-border {
  border-left: 10px solid var(--primary-color);
}

.card-header {
  background-color: white !important;
}

.mat-step-header .mat-step-icon-selected {
  background-color: var(--primary-color) !important;
}

.mat-step-icon-state-edit {
  background-color: var(--primary-color) !important;
}

.mat-step-header:nth-of-type(2) {
  display: none;
}

.mat-step-header:nth-of-type(3) {
  display: none;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin-top: 4px !important;
}

body {
  margin: 0;
  padding: 0;
  background: #f2f3f5;
}

.mat-toolbar.mat-primary {
  background: #fff;
  color: #000;
  border-bottom: 1px solid #ddd;
}

html,
body {
  height: 100%;
}

.form {
  max-width: 600px;
}

* {
  box-sizing: border-box;
}

.logoer {
  display: flex;
}

a.logo {
  outline: none !important;
}

img.logo {
  height: auto;
  width: 250px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
}

img.logo-login {
  width: 100%;
  height: auto;
  cursor: pointer;
}

[hidden] {
  display: none;
}

.inactive {
  background-color: grey;
  pointer-events: none;
}

.box {
  display: flex;
  border-bottom: 1px solid grey;
  padding: 2px;
  width: 700px;
  align-items: center;
  justify-content: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 325px;
}

.login-card {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: 10px 40px;
  margin: 0 auto;
  border: 1px solid #ddd;
}

.userinfo {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 10px;
}

.login-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 104px;
  width: 104px;
  margin: 20px;
  border-radius: 50%;
  background: var(--primary-color);
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.login-logo-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75px;
}

.switch-button {
  max-width: 120px;
  width: 100%;
  margin: 16px 0;
}

.spinner {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: white;
  margin: 0 auto;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.selector {
  display: flex;
  margin: 2px 2px;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 10px;
  transition-duration: 0.3s;
  transition-property: background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  background: var(--primary-color, #00b96f);
  color: #fff;
  white-space: nowrap;
}

.disabled {
  background: grey;
}

.content {
  text-align: center;
}

.outgoing {
  background-color: var(--primary-color);
}

::ng-deep .mat-step-header .mat-step-icon-selected {
  background-color: var(--primary-color);
}

::ng-deep .mat-step-header .mat-step-icon {
  background-color: var(--primary-color);
}

.card-res-table {
  overflow-x: auto;
}

.card-text {
  background: white;
  flex: 1 400px;
  margin: 16px;
  border-radius: 2px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-space-between {
  display: flex;
  padding: 4px;
}

router-link a {
  text-decoration: none;
  color: inherit;
}

router-link[active] a {
  color: var(--primary-color);
}

.link {
  text-align: center;
  margin: 16px 0;
  background: none;

  cursor: pointer;
  color: var(--primary-color) !important;
}

.link-clean {
  background: none;
  cursor: pointer;
  color: var(--primary-color) !important;
}

.noncolor {
  text-decoration: underline;
  color: black !important;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
  color: var(--secondary-color);
}

.link:hover {
  text-decoration: none;
  color: var(--secondary-color);
}

divider {
  border-bottom: 1px solid grey;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: var(--third-color) !important;
  border-color: var(--third-color) !important;
  color: white !important;
}

.btn-loading,
.btn-loading:hover,
.btn-loading:active,
.btn-loading:visited,
.btn-loading:focus {
  cursor: wait;
}

.overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.286);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: var(--primary-color) !important;
}

.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: var(--primary-color) !important;
}

.form-check-input {
  margin-top: 0.15rem;
}

:-moz-any(.form-check-input) {
  margin-top: 0.35rem;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-color);
}

mat-form-field {
  width: 100%;
}

.required:after {
  content: " *";
  color: red;
  font-weight: 100;
}

.box {
  overflow: hidden;
}

td {
  vertical-align: middle !important;
}

.mat-card-header {
  padding: 5px !important;
}

.mat-card-subtitle {
  color: black !important;
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-card {
  word-break: break-word;
}

.stick-bottom {
  vertical-align: bottom !important;
}

.card-wrapper {
  height: calc(100% + 2px);
}

.card-row-header {
  min-height: 5vw;
}

.input-width {
  max-width: 300px;
}

.mat-card {
  display: flex;
  flex-direction: column;
}

.mat-card-content {
  min-height: 5vh;
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

.chart {
  width: 100%;
  min-height: 450px;
}

.tip {
  font-size: 14px;
}

.headingWithIcon mat-icon {
  margin-right: 10px;
}

.headingWithIcon h2,
.headingWithIcon h1,
.headingWithIcon mat-icon {
  display: inline-block;
  vertical-align: top;
}

.mat-horizontal-content-container {
  overflow: visible !important;
}

.ng-autocomplete input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

th.type,
td.type {
  background-color: var(--secondary-color);
  color: white !important;
  border-radius: 4px;
}

td.type_right,
th.type_right {
  background-color: var(--secondary-color);
  color: white !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

th.type_left,
td.type_left {
  background-color: var(--secondary-color);
  color: white !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

td.border_radius {
  border-radius: 4px;
}

td.border_radius_left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

td.border_radius_right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

tr.type {
  background-color: var(--secondary-color);
  color: white !important;
}

td.nowrap {
  white-space: nowrap;
}

table.fit > thead > tr > th,
table.fit > thead > tr > td {
  white-space: nowrap;
  width: 1%;
}

table.fit th.expand,
table.fit td.expand {
  width: initial;
}

p.type {
  background-color: var(--secondary-color);
  color: white !important;
  border-radius: 4px;
}

div.type {
  background-color: var(--secondary-color);
  color: white !important;
  border-radius: 4px;
}

/* Nested tables */
tr.secondary {
  background-color: var(--fundmarket-grey);
  color: var(--secondary-color) !important;
}

/*remove arrows on input field with type numbers*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.alert-info {
  --bs-alert-color: white !important;
  --bs-alert-bg: var(--primary-color) !important;
  --bs-alert-border-color: var(--primary-color) !important;
}

.mat-icon {
  vertical-align: text-bottom !important;
}

.bs-card-height {
  height: 100% !important;
}

a.succeslink {
  text-decoration: underline !important;
}

.material-icons-local {
  font-family: "Material Icons";
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  font-feature-settings: "liga";
}

.compose-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color, #62bdfd);
  height: 48px;
  color: white;
  cursor: pointer;
  text-align: center;
}

.compose-button:hover {
  opacity: 0.9;
}

.chrome {
  padding: 16px;
  border-right: 1px solid #ddd;
  height: calc(100vh - 64px - 48px);
  flex: 1;
}

@media only screen and (max-width: 768px) {
  .inbox {
    height: 40vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
  }
}

.inbox {
  background: white;
  margin-top: 10px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}

.link[active] .thread {
  color: var(--primary-color);
}

.thread {
  display: flex;
  padding: 12px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-bottom: 1px solid #ddd;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.thread:hover {
  background: rgba(0, 0, 20, 0.05);
}

.thread-overview {
  flex: 90%;
  color: black;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.thread-unread {
  background-color: lightgray;
}

.text-unread {
  font-weight: bold;
}

.thread-date {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-weight: 500;
}

router-link > a {
  text-decoration: none;
  color: inherit;
}

.content {
  justify-content: center;
  flex: 4;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .content {
    height: 40vh !important;
    overflow-y: scroll !important;
  }
}

.message {
  padding: 16px;
  text-align: left;
}

.message:not(:first-child) {
  border-top: 1px solid #ddd;
}

.message-el {
  margin: 0;
  padding: 4px;
}

.message-content {
  margin: 0;
  padding: 8px 4px;
}

.reply-form {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.form-label {
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  font-weight: 500;
}

.reply {
  border: 1px solid #ddd;
  resize: none;
  height: 200px;
  margin-top: 4px;
  font-size: 14px;
}

.message-text {
  border: 1px solid #ddd;
  resize: none;
  height: 200px;
  margin-top: 4px;
  font-size: 14px;
  width: 100%;
}

.submit-button {
  align-self: flex-end;
  margin: 8px 32px;
}

.threads {
  padding: 16px;
  text-align: left;
}

.is-primary-color {
  color: var(--secondary-color) !important;
}

.is-white {
  color: white !important;
}

.bg-primary {
  background-color: var(--secondary-color) !important;
}

.border-3 {
  border-radius: 3px;
}

.border-top-3 {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.bootstrap-grey {
  background-color: #e9ecef;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.mw-30 {
  max-width: 30% !important;
}

.mw-35 {
  max-width: 35% !important;
}

.mw-40 {
  max-width: 40% !important;
}

.mw-45 {
  max-width: 45% !important;
}

h1.name {
  margin-left: 8px;
}

.logged-in-user-not-approved {
  text-align: left;
  padding-left: 16px;
  padding-top: 16px;
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.logged-in-user {
  text-align: left;
  padding: 16px;
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.not-approved {
  text-align: left;
  padding-left: 16px;
  padding-bottom: 16px;
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.container-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.is-mobile .toolbar {
  position: fixed;
  z-index: 2;
}

.sidenav-container {
  flex: 1;
}

.is-mobile .sidenav-container {
  flex: 1 0 auto;
}

.font-justering {
  font-size: 16px !important;
}
